export const LOGIN_PENDING = 'AUTH_MODULE/LOGIN_PENDING'
export const LOGIN_SUCCESS = 'AUTH_MODULE/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'AUTH_MODULE/LOGIN_FAILURE'
export const TWO_FACTOR_PENDING = 'AUTH_MODULE/TWO_FACTOR_PENDING'
export const TWO_FACTOR_SUCCESS = 'AUTH_MODULE/TWO_FACTOR_SUCCESS'
export const TWO_FACTOR_FAILURE = 'AUTH_MODULE/TWO_FACTOR_FAILURE'
export const REGISTER = 'AUTH_MODULE/REGISTER'
export const VERIFY_EMAIL = 'AUTH_MODULE/VERIFY_EMAIL'
export const VERIFY_EMAIL_SUCCESS = 'AUTH_MODULE/VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_ERROR = 'AUTH_MODULE/VERIFY_EMAIL_ERROR'

export function fetchLogin({
  username,
  password,
  rememberMe,
  role,
  onSuccess,
  onFailure,
  on2FA
}) {
  return {
    type: LOGIN_PENDING,
    username,
    password,
    rememberMe,
    role,
    onSuccess,
    onFailure,
    on2FA
  }
}

export function loginSuccess() {
  return {
    type: LOGIN_SUCCESS
  }
}

export function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error
  }
}

export function register({ data, onSuccess, onFailure }) {
  return {
    type: REGISTER,
    data,
    onSuccess,
    onFailure
  }
}

export function fetchTwoFactor(payload) {
  return {
    type: TWO_FACTOR_PENDING,
    payload
  }
}

export function twoFactorSuccess() {
  return {
    type: TWO_FACTOR_SUCCESS
  }
}

export function twoFactorFailure() {
  return {
    type: TWO_FACTOR_FAILURE
  }
}

export function verifyEmail(params) {
  return {
    type: VERIFY_EMAIL,
    payload: params
  }
}

export function verifyEmailSuccess(data) {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    payload: { ...(data || {}), status: 'successful' }
  }
}

export function verifyEmailError(error) {
  return {
    type: VERIFY_EMAIL_ERROR,
    payload: error
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import RecoveryCodesContent from './RecoveryCodesContent'
import DefaultModal from 'components/Modal/DefaultModal'
import { recoveryCodesSelector } from 'selectors/appSelectors'
import { setRecoveryCodes } from 'slices/appSlice'

const RecoveryCodesModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const recoveryCodes = useSelector(recoveryCodesSelector)

  return (
    <DefaultModal
      modalTitle={t('Recovery Codes')}
      open={!!recoveryCodes?.length}
      hasCloseIcon={false}
      contentPaddingBottom
      hasCancelBtn={false}
      onClickSave={() => {
        dispatch(setRecoveryCodes([]))
      }}
      buttonPrimaryText={t('Close')}
    >
      <RecoveryCodesContent recoveryCodes={recoveryCodes} />
    </DefaultModal>
  )
}

export default RecoveryCodesModal

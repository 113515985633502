import React from 'react'
import { Tooltip } from '@material-ui/core'
import { List } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const ListViewIcon = () => {
  const { t } = useTranslation()

  return (
    <Tooltip arrow title={t('List View')}>
      <List />
    </Tooltip>
  )
}

export default ListViewIcon

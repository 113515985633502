import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import HTMLContentSaga from 'sagas/HTMLContentSaga'

const actions = [
  types.POST_HTML_CONTENT_SUCCESS,
  types.PUT_HTML_CONTENT_SUCCESS,
  types.DELETE_HTML_CONTENT_SUCCESS
]

const htmlContentInvalidateCache = () =>
  actions.map(action =>
    takeLatest(action, HTMLContentSaga.invalidateHtmlContentTags)
  )

export default htmlContentInvalidateCache

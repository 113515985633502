import React from 'react'
import QRCode from 'react-qr-code'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

import RectLoader from 'components/Loaders/RectLoader'
import { Text } from 'components/Typography'
import { FormControlInput } from 'components/Form'

const useStyles = makeStyles(({ spacing }) => ({
  QRCode: {
    padding: spacing(2, 10),
    height: 'auto',
    maxWidth: '100%',
    width: '100%'
  }
}))

const ActivationContent = ({
  isLoading,
  qrCodeUrl,
  values,
  errors,
  touched,
  handleChange,
  handleBlur
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      {isLoading && <RectLoader height={408} padding="16 80" />}
      {qrCodeUrl && (
        <>
          <Text>
            {t(
              'Please scan the QR code with a Google Authenticator and enter the authentication code to complete the setup.'
            )}
          </Text>
          <QRCode
            size={256}
            className={classes.QRCode}
            value={qrCodeUrl}
            viewBox="0 0 256 256"
          />
        </>
      )}

      <FormControlInput
        label={t('Code')}
        value={values.code}
        name="code"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.code}
        touched={touched.code}
      />
    </>
  )
}

export default ActivationContent

import { makeStyles, Table, TableBody } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import EmptyPlaceholder from 'components/EmptyPlaceholder'
import { LibraryLoader } from 'components/Loaders'
import { TablePaper } from 'components/Paper'
import { shapeOfMeta } from 'constants/initialLibraryState'
import { TableLibraryFooter, TableLibraryHead } from './index'
import useConfirmation from 'hooks/useConfirmation'
import { getPlaceholderRowsCount } from 'utils/libraryUtils'
import { getChangedSortParams, reorderColumns } from 'utils/tableUtils'
import { useUserRole } from 'hooks/tableLibrary'
import { columnWidths } from './TableLibraryHead'

const useStyles = makeStyles(({ palette, type, mixins }) => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  table: {
    minWidth: 1020,
    height: 0 // to fix Firefox style behavior
  },
  name: {
    fontWeight: 'bold'
  },
  headerRoot: {
    background: palette[type].tableLibrary.head.background
  },
  horizontalScroll: {
    overflowX: 'auto',
    ...mixins.styledScrollbar[type]
  },
  headBulkActionsWrap: ({ bulkActionWidth }) => ({
    width: bulkActionWidth,
    paddingLeft: 5,
    height: '100%',
    backgroundColor: 'transparent'
  })
}))

const BaseTable = ({
  placeholderMessage = '',
  tableFooterTagTooltipTitle,
  meta = shapeOfMeta,
  fetcher = f => f,
  isFetchAllowed = true,
  deleteSelectedItems = f => f,
  bulkDeleteHandler,
  children,
  noType = true,
  columns = [],
  loading = true,
  isCustomLoading = false,
  columnWidth = {},
  sortOptions = {
    sort: '',
    order: 'asc'
  },
  noBulkTagsAssign,
  hasDelete,
  canBulkEdit,
  canBulkDelete,
  hasMultiSelect = true,
  selectedListClearAllowed = true,
  selectedList,
  preferenceActions,
  hideTableFooter = false,
  t,
  onRequestSort,
  serverSideSorting = true,
  showEditRows = true,
  showActionRow = false,
  tableRootClass = '',
  headerCellClassName = '',
  disableSort = false,
  emptyPlaceholderRootClass = '',
  isSsoVisible = false,
  noHideColumns = [],
  tableFooterWrapClasName = '',
  paginationClasses,
  tablePaperWrapperClass = '',
  tableMaxWidth,
  isStickyHeader = false,
  headerRootClassName = '',
  hasEnabledCheckBox = true,
  hasHorizontalScroll = false,
  footerActionsComponent,
  staticColumns = [],
  checkboxPaddingVariant = 'medium',
  additionalBulkWidth = 0,
  headBulkActionsWrapClass = '',
  libraryTypeStorePath = '',
  libraryType = '',
  storeType = '',
  bulkActionData,
  hideGroupEntity,
  customLoader,
  isFetching
}) => {
  const {
    clear: clearSelectedList = f => f,
    selectedIds = [],
    isPageSelect = false,
    pageSelect
  } = useMemo(() => selectedList || {}, [selectedList])

  const {
    changeColumns = f => f,
    toggleDisplayColumn = f => f,
    changeRecordsPerPage = f => f,
    changeSorting = f => f,
    resetColumns = f => f
  } = useMemo(() => preferenceActions || {}, [preferenceActions])

  const bulkActionWidth = useMemo(() => {
    let staticColumnsWidth = 0

    staticColumns.forEach(column => {
      staticColumnsWidth += columnWidth[column.id]
    })

    return (
      staticColumnsWidth + columnWidths.checkboxColumn + additionalBulkWidth
    )
  }, [staticColumns, columnWidth, additionalBulkWidth])

  const classes = useStyles({
    bulkActionWidth
  })

  const { showConfirmation } = useConfirmation()
  const role = useUserRole()

  const withHorizontalScroll = useMemo(
    () => role.system || hasHorizontalScroll,
    [role, hasHorizontalScroll]
  )

  const sortParams = useMemo(
    () => ({
      sort: sortOptions.sort || sortOptions.orderBy || '',
      order: sortOptions.order || ''
    }),
    [sortOptions]
  )

  const { sort, order } = sortParams

  const handleRequestSort = useCallback(
    (event, column) => {
      const sortParams = getChangedSortParams(sort, column, order)

      if (onRequestSort) {
        onRequestSort(sortParams)
      }
      if (serverSideSorting) {
        fetcher({
          page: 1,
          limit: meta.perPage,
          ...sortParams
        })
      }
      changeSorting(sortParams.sort, sortParams.order)
    },
    [
      sort,
      order,
      fetcher,
      meta.perPage,
      changeSorting,
      onRequestSort,
      serverSideSorting
    ]
  )

  const handleChangePage = useCallback(
    ({ selected }) => {
      fetcher({
        page: selected + 1,
        limit: meta.perPage,
        ...sortParams
      })
    },
    [fetcher, meta.perPage, sortParams]
  )

  const showLoader = useMemo(
    () =>
      (isCustomLoading && loading) ||
      (!isCustomLoading && (!isFetchAllowed || isFetching)),
    [isCustomLoading, loading, isFetchAllowed, isFetching]
  )

  const handleClickDeleteSelectedItems = useCallback(
    () => {
      showConfirmation(t('remove_bulk_modal_text'), () => {
        deleteSelectedItems(selectedIds)
        selectedListClearAllowed && clearSelectedList()
      })
    },
    // eslint-disable-next-line
    [
      deleteSelectedItems,
      selectedIds,
      clearSelectedList,
      selectedListClearAllowed
    ]
  )

  const handleChangeRowsPerPage = useCallback(
    limit => {
      fetcher(
        {
          page: 1,
          limit,
          ...sortParams
        },
        []
      )
      changeRecordsPerPage(limit)
    },
    [changeRecordsPerPage, fetcher, sortParams]
  )

  const handlePressJumper = useCallback(
    ({ target: { value }, key }) => {
      const page = Number.parseInt(value)

      if (key === 'Enter' && page <= meta.lastPage) {
        fetcher({
          page,
          limit: meta.perPage,
          ...sortParams
        })
      }
    },
    [fetcher, meta.lastPage, meta.perPage, sortParams]
  )

  const handleReorder = useCallback(
    ({ source, destination }) => {
      if (source && destination) {
        changeColumns(
          reorderColumns(source, destination, columns).map((column, index) => ({
            ...column,
            sortOrder: index
          }))
        )
      }
    },
    [columns, changeColumns]
  )
  const bulkActions = useCallback(
    ({ hasPagination, tableFooterWrapClassName = '', headSection = false }) => {
      if (!hideTableFooter) {
        return (
          <TableLibraryFooter
            tagTooltipTitle={tableFooterTagTooltipTitle}
            page={meta.currentPage}
            allSelected={isPageSelect}
            onSelectAllClick={pageSelect}
            handleSelect={pageSelect}
            selected={selectedIds}
            pageCount={meta.lastPage}
            perPage={+meta.perPage}
            onPageChange={handleChangePage}
            onPressJumper={handlePressJumper}
            handleClickDeleteSelectedItems={
              bulkDeleteHandler || handleClickDeleteSelectedItems
            }
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            noBulkTagsAssign={noBulkTagsAssign}
            hasDelete={hasDelete}
            canEdit={canBulkEdit}
            canDelete={canBulkDelete}
            hasMultiSelect={hasMultiSelect}
            isSelectAllDisabled={!hasEnabledCheckBox}
            totalCount={meta.total}
            paginationClasses={paginationClasses}
            footerActionsComponent={footerActionsComponent}
            tableFooterWrapClasName={classNames(
              tableFooterWrapClasName,
              tableFooterWrapClassName
            )}
            hasPagination={hasPagination}
            headSection={headSection}
            libraryType={libraryType}
            storeType={storeType}
            libraryTypeStorePath={libraryTypeStorePath}
            data={bulkActionData}
            clearSelected={clearSelectedList}
            fetcher={fetcher}
            hideGroupEntity={hideGroupEntity}
          />
        )
      }
    },
    [
      tableFooterTagTooltipTitle,
      handleClickDeleteSelectedItems,
      handleChangeRowsPerPage,
      tableFooterWrapClasName,
      bulkDeleteHandler,
      footerActionsComponent,
      paginationClasses,
      libraryTypeStorePath,
      clearSelectedList,
      isPageSelect,
      pageSelect,
      handleChangePage,
      handlePressJumper,
      hasEnabledCheckBox,
      hideTableFooter,
      noBulkTagsAssign,
      selectedIds,
      hasDelete,
      canBulkEdit,
      canBulkDelete,
      hasMultiSelect,
      libraryType,
      storeType,
      bulkActionData,
      hideGroupEntity,
      meta.currentPage,
      meta.lastPage,
      meta.perPage,
      meta.total,
      fetcher
    ]
  )

  return showLoader ? (
    customLoader ? (
      customLoader
    ) : (
      <LibraryLoader rowCount={getPlaceholderRowsCount(meta.perPage, 70)} />
    )
  ) : meta.count ? (
    <TablePaper className={classes.root}>
      <div
        className={classNames(classes.tableWrapper, tablePaperWrapperClass, {
          [classes.horizontalScroll]: withHorizontalScroll
        })}
      >
        <Table className={classNames(classes.table, tableRootClass)}>
          <TableLibraryHead
            editRows={showEditRows}
            actionRow={showActionRow}
            order={order}
            orderBy={sort}
            allSelected={isPageSelect}
            onSelectAllClick={pageSelect}
            onRequestSort={handleRequestSort}
            rowCount={meta.count}
            columns={columns}
            noType={noType}
            handleColumnChange={toggleDisplayColumn}
            handleReorder={handleReorder}
            handleColumnReset={resetColumns}
            columnWidth={columnWidth}
            disableSort={disableSort}
            hasMultiSelect={hasMultiSelect}
            hasEnabledCheckBox={hasEnabledCheckBox}
            headerCellClassName={headerCellClassName}
            isSsoVisible={isSsoVisible}
            noHideColumns={noHideColumns}
            tableMaxWidth={tableMaxWidth}
            isStickyHeader={isStickyHeader}
            headerRootClassName={classNames(
              classes.headerRoot,
              headerRootClassName
            )}
            staticColumns={staticColumns}
            checkboxPaddingVariant={checkboxPaddingVariant}
            hasHorizontalScroll={hasHorizontalScroll}
            {...(hasMultiSelect && {
              bulkActions: bulkActions({
                hasPagination: false,
                headSection: true,
                tableFooterWrapClassName: classNames(
                  classes.headBulkActionsWrap,
                  headBulkActionsWrapClass
                )
              })
            })}
            numSelected={selectedIds.length}
          />
          <TableBody>{children}</TableBody>
        </Table>
      </div>
      {bulkActions({ hasPagination: true })}
    </TablePaper>
  ) : (
    <EmptyPlaceholder
      text={t(placeholderMessage)}
      rootClassName={emptyPlaceholderRootClass}
    />
  )
}

BaseTable.propTypes = {
  meta: PropTypes.object,
  fetcher: PropTypes.func,
  columns: PropTypes.array,
  deleteSelectedItems: PropTypes.func,
  placeholderMessage: PropTypes.string,
  tableFooterTagTooltipTitle: PropTypes.string,
  noType: PropTypes.bool,
  noBulkTagsAssign: PropTypes.bool,
  hasDelete: PropTypes.bool,

  sortOptions: PropTypes.shape({
    sort: PropTypes.string,
    order: PropTypes.string
  }),

  selectedList: PropTypes.shape({
    clear: PropTypes.func,
    selectedIds: PropTypes.array,
    isPageSelect: PropTypes.bool,
    pageSelect: PropTypes.func
  }),

  preferenceActions: PropTypes.shape({
    changeRecordsPerPage: PropTypes.func,
    toggleDisplayColumn: PropTypes.func,
    changeColumns: PropTypes.func,
    changeSorting: PropTypes.func
  }),
  hideTableFooter: PropTypes.bool,
  onRequestSort: PropTypes.func,
  serverSideSorting: PropTypes.bool,
  disableSort: PropTypes.bool
}

export default withTranslation('translations')(BaseTable)

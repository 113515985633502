import { isFeatureAvailable } from 'utils/api/featureAvailability'
import { ENTERPRISE_ROLE, ORG_ROLE, RESELLER_ROLE, SYSTEM_ROLE } from '../api'
import { DEVICE_RESOLUTION_FEATURE } from 'constants/featureConstants'
import _get from 'lodash/get'
import { columnTypes } from '../tableConstants'
import DeviceNameViewAgCell from 'components/Pages/Admin/DeviceLibrary/DeviceAdvancedTable/DeviceNameViewAgCell'
import DeviceStatusAgCell from 'components/Pages/Admin/DeviceLibrary/DeviceAdvancedTable/DeviceStatusAgCell'
import DeviceMacAddressViewAgCell from 'components/Pages/Admin/DeviceLibrary/DeviceAdvancedTable/DeviceMacAddressViewAgCell'
import { insetColons } from 'utils/macAddressUtils'
import DeviceConnectionAgCell from '../../components/Pages/Admin/DeviceLibrary/DeviceAdvancedTable/DeviceConnectionAgCell'
import DeviceRamAgCell from '../../components/Pages/Admin/DeviceLibrary/DeviceAdvancedTable/DeviceRAMAgCell'
import DeviceVersionAgCell from '../../components/Pages/Admin/DeviceLibrary/DeviceAdvancedTable/DeviceVersionAgCell'
import DeviceLogLevelCell from 'components/Pages/Admin/DeviceLibrary/DeviceAdvancedTable/DeviceLogLevelCell'

export const columnHeader = {
  osVersion: 'osVersion',
  browserVersion: 'browserVersion'
}

export const defaultColumnWidth = {
  id: 96,
  deviceTypeLogo: 96,
  deviceType: 150,
  alias: 200,
  city: 200,
  group: 200,
  clientName: 200,
  clientId: 200,
  licenseExpiration: 180,
  serviceLevel: 160,
  logLevel: 160,
  updatedAt: 160,
  createdAt: 160,
  firmware: 160,
  appliedFirmware: 160,
  tag: 200,
  status: 110,
  lastReboot: 160,
  lastCheckInUTC: 160,
  lanIP: 160,
  wanIP: 160,
  macAddress: 160,
  memoryUtilization: 150,
  storageUtilization: 150,
  osVersion: 200,
  remoteSupportVersion: 200,
  serialNumber: 160,
  soNumber: 200,
  poNumber: 200,
  clientPoNumber: 200,
  browserVersion: 160,
  processor: 160,
  featurePackageTitle: 200,
  teamviewerId: 160,
  remoteSupportId: 200,
  invoiceNumber: 160,
  lastThumbUpdated: 160,
  playbackModuleVersion: 200,
  renderAssetPackVersion: 200,
  staticAssetPackVersion: 200,
  fontAssetPackVersion: 200,
  nodejsVersion: 160,
  resolution: 150,
  appRestart: 110,
  statusChange: 160,
  screenOrientation: 200,
  synchronousConnection: 110,
  securityStatus: 160,
  supportTokenStatus: 96
}

export const staticColumns = [
  { id: 'alias', label: 'Name', display: true, forRoles: ['org'] },
  {
    id: 'name',
    label: 'Name',
    display: true,
    forRoles: ['system', 'enterprise'],
    cellRenderer: DeviceNameViewAgCell
  }
]

export const initialColumns = t =>
  [
    {
      id: 'id',
      label: 'ID',
      forRoles: ['system'],
      align: 'center',
      display: false
    },
    {
      id: 'deviceType',
      label: 'Type',
      align: 'left',
      display: true,
      valueGetter: ({ data: { deviceType } }) => _get(deviceType, 'name', 'N/A')
    },
    {
      id: 'city',
      label: 'Location',
      forRoles: ['org'],
      align: 'center',
      display: true
    },
    {
      id: 'group',
      label: 'Groups',
      forRoles: [ORG_ROLE, ENTERPRISE_ROLE],
      align: 'center',
      display: true
    },
    {
      id: 'clientName',
      label: 'Client',
      forRoles: ['system', 'enterprise'],
      cellDataType: columnTypes.client
    },
    {
      id: 'teamviewerId',
      label: 'Teamviewer ID',
      align: 'center',
      display: true,
      forRoles: ['system']
    },
    {
      id: 'remoteSupportId',
      label: 'Remote Support ID',
      align: 'center',
      display: false,
      forRoles: ['system']
    },
    {
      id: 'soNumber',
      label: 'SO Number',
      align: 'center',
      display: false,
      forRoles: ['system']
    },
    {
      id: 'poNumber',
      label: 'Batch Number',
      align: 'center',
      display: false,
      forRoles: ['system']
    },
    {
      id: 'clientPoNumber',
      label: 'Client PO Number',
      align: 'center',
      display: false,
      forRoles: ['system']
    },
    {
      id: 'serialNumber',
      label: 'Serial Number',
      align: 'center',
      display: false
    },
    {
      id: 'licenseExpiration',
      label: 'License Expiration',
      align: 'center',
      display: true,
      cellDataType: columnTypes.date,
      valueGetter: ({ data: { deviceLicense } }) =>
        deviceLicense?.expirationDate,
      isUTC: true
    },
    {
      id: 'serviceLevel',
      label: 'Service Level',
      align: 'center',
      display: true
    },
    {
      id: 'logLevel',
      label: 'Log Level',
      align: 'center',
      forRoles: ['system'],
      cellRenderer: DeviceLogLevelCell
    },
    {
      id: 'updatedAt',
      label: 'Last Update',
      align: 'center',
      display: true,
      cellDataType: columnTypes.dateTime
    },
    {
      id: 'createdAt',
      label: 'Date Added',
      align: 'center',
      forRoles: ['system'],
      cellDataType: columnTypes.dateTime
    },
    {
      id: 'createdAt',
      label: 'Device Age',
      align: 'center',
      forRoles: ['org', 'enterprise'],
      cellDataType: columnTypes.dateTime
    },
    {
      id: 'firmware',
      label: 'Current App.',
      align: 'center',
      display: false
    },
    {
      id: 'appliedFirmware',
      label: 'Applied App.',
      align: 'center',
      display: false
    },
    {
      id: 'tag',
      label: 'Tags',
      align: 'center',
      cellDataType: columnTypes.tags,
      isDevicesLibrary: true
    },
    {
      id: 'status',
      label: 'Status',
      align: 'center',
      cellRenderer: DeviceStatusAgCell
    },
    {
      id: 'lastReboot',
      label: 'Last Reboot',
      align: 'center',
      cellDataType: columnTypes.dateTime
    },
    {
      id: 'lastCheckInUTC',
      label: 'Last Check-in',
      align: 'center',
      cellDataType: columnTypes.dateTime
    },
    { id: 'lanIP', label: 'Lan IP', align: 'center' },
    { id: 'wanIP', label: 'Wan IP', align: 'center' },
    {
      id: 'macAddress',
      label: 'MAC Address',
      align: 'center',
      cellRenderer: DeviceMacAddressViewAgCell,
      valueFormatter: ({ value }) => {
        if (!value) return 'N/A'

        return value
          .map(({ macAddress }) => insetColons(macAddress))
          .join(' | ')
      }
    },
    {
      id: 'memoryUtilization',
      label: 'RAM',
      align: 'center',
      cellRenderer: DeviceRamAgCell
    },
    { id: 'storageUtilization', label: 'Hard Disk', align: 'center' },
    { id: 'osVersion', label: 'OS Version', align: 'center' },
    {
      id: 'remoteSupportVersion',
      label: 'Remote Support Version',
      align: 'center',
      forRoles: ['system']
    },
    { id: 'browserVersion', label: 'Browser', align: 'center' },
    { id: 'processor', label: 'Processors', align: 'center' },
    {
      id: 'featurePackageTitle',
      label: 'Device Package',
      align: 'center',
      valueGetter: ({ data: { featurePackage } }) =>
        _get(featurePackage, 'title', t('N/A'))
    },
    {
      id: 'invoiceNumber',
      label: 'Invoice Number',
      align: 'center',
      display: false
    },
    {
      id: 'lastThumbUpdated',
      label: 'Last Preview',
      align: 'center',
      valueGetter: ({ data: { lastThumbnailUpdated } }) => lastThumbnailUpdated,
      cellDataType: columnTypes.dateTime
    },
    {
      id: 'playbackModuleVersion',
      label: 'PM Version',
      align: 'center',
      cellRenderer: DeviceVersionAgCell
    },
    {
      id: 'renderAssetPackVersion',
      label: 'Render AP Version',
      align: 'center',
      cellRenderer: DeviceVersionAgCell
    },
    {
      id: 'staticAssetPackVersion',
      label: 'Static AP Version',
      align: 'center',
      cellRenderer: DeviceVersionAgCell
    },
    {
      id: 'fontAssetPackVersion',
      label: 'Font AP Version',
      align: 'center',
      cellRenderer: DeviceVersionAgCell
    },
    {
      id: 'nodejsVersion',
      label: 'Node Version',
      forRoles: ['system'],
      align: 'center'
    },
    ...(isFeatureAvailable(DEVICE_RESOLUTION_FEATURE)
      ? [
          {
            id: 'resolution',
            label: 'Resolution',
            align: 'center',
            forRoles: ['system', 'org']
          }
        ]
      : []),
    {
      id: 'synchronousConnection',
      label: 'Connection',
      tooltip: `${t('Synchronous Connection')} (${t('Web-sockets')})`,
      forRoles: ['system'],
      align: 'center',
      cellRenderer: DeviceConnectionAgCell
    },
    {
      id: 'appRestart',
      label: 'App Restarts',
      align: 'center',
      forRoles: ['system']
    },
    {
      id: 'statusChange',
      label: 'Status Changes',
      align: 'center',
      forRoles: ['system']
    },
    {
      id: 'screenOrientation',
      label: 'Screen Orientation',
      align: 'center',
      forRoles: ['system', 'org']
    },
    {
      id: 'securityStatus',
      label: 'Device Security',
      align: 'center'
    },
    {
      id: 'supportTokenStatus',
      label: 'Support Token',
      align: 'center',
      display: true,
      forRoles: ['system']
    }
  ].map(i => {
    if (!i.display) {
      i.display = false
    }
    return i
  })

export const columnExceptions = {
  [ORG_ROLE]: [
    'playbackModuleVersion',
    'renderAssetPackVersion',
    'staticAssetPackVersion',
    'fontAssetPackVersion'
  ],
  [ENTERPRISE_ROLE]: [
    'playbackModuleVersion',
    'renderAssetPackVersion',
    'staticAssetPackVersion',
    'fontAssetPackVersion'
  ],
  [SYSTEM_ROLE]: [],
  [RESELLER_ROLE]: [
    'playbackModuleVersion',
    'renderAssetPackVersion',
    'staticAssetPackVersion',
    'fontAssetPackVersion'
  ]
}

export const getColumnExceptionsByRole = role => {
  return columnExceptions.hasOwnProperty(role) ? columnExceptions[role] : []
}

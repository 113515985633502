import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'

export const usersApi = createAppApi('usersQuery', {
  tagTypes: [apiTags.user],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      url: '/user',
      name: 'User',
      tags: [apiTags.user]
    }),
    putGroupPermission: builder.mutation({
      query: ({ groupId, entity, ...data }) => ({
        method: 'PUT',
        url: `/user/group/${groupId}/${entity}/permission`,
        data
      })
    }),
    putUserGroupPermission: builder.mutation({
      query: ({ userId, entity, ...data }) => ({
        method: 'PUT',
        url: `/user/${userId}/${entity}/permission`,
        data
      })
    })
  })
})

export const {
  useLazyGetUsersQuery,
  useGetUsersQuery,
  useLazyGetUserQuery,
  usePostUserMutation,
  usePutUserMutation,
  useDeleteUserMutation,
  usePutGroupPermissionMutation,
  usePutUserGroupPermissionMutation
} = usersApi

import React from 'react'
import { Tooltip } from '@material-ui/core'
import { GridOn } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const GridViewIcon = () => {
  const { t } = useTranslation()

  return (
    <Tooltip arrow title={t('Grid View')}>
      <GridOn />
    </Tooltip>
  )
}

export default GridViewIcon

import { createAppApi } from '../services/api'

export const authenticationApi = createAppApi('authenticationQuery', {
  endpoints: builder => ({
    recovery: builder.mutation({
      query: ({ role, ...data }) => ({
        method: 'POST',
        url: `${role}/recovery`,
        data
      })
    }),
    checkLogin: builder.mutation({
      query: data => ({
        method: 'POST',
        url: `/login/check`,
        data
      })
    }),
    passwordlessLogin: builder.query({
      query: ({ token, signature, expires }) => ({
        method: 'GET',
        url: 'login/passwordless',
        //strict order is required
        params: {
          expires,
          token,
          signature
        }
      })
    }),
    registerGoogle2fa: builder.mutation({
      query: () => ({
        method: 'POST',
        url: 'google2fa/register'
      })
    }),
    activateGoogle2fa: builder.mutation({
      query: data => ({
        method: 'POST',
        url: 'google2fa/activate',
        data
      })
    }),
    rotateGoogle2fa: builder.mutation({
      query: () => ({
        method: 'POST',
        url: 'google2fa/recovery/rotate'
      })
    }),
    deactivateGoogle2fa: builder.mutation({
      query: () => ({
        method: 'POST',
        url: 'google2fa/deactivate'
      })
    }),
    googleTfaLogin: builder.mutation({
      query: data => ({
        method: 'POST',
        url: 'googleTwoFactorLogin',
        data
      })
    })
  })
})

export const {
  useRecoveryMutation,
  useCheckLoginMutation,
  useLazyPasswordlessLoginQuery,
  useRegisterGoogle2faMutation,
  useActivateGoogle2faMutation,
  useRotateGoogle2faMutation,
  useDeactivateGoogle2faMutation,
  useGoogleTfaLoginMutation
} = authenticationApi

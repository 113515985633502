import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from '@reduxjs/toolkit'
import { useDebouncedCallback } from 'use-debounce'
import { makeStyles } from '@material-ui/core'
import { useParams } from 'react-router'

import { getDeviceNocGeneralItems } from 'actions/deviceNocActions'
import DevicePreviewModal from 'components/Modal/DevicePreviewModal'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import queryParamsHelper from 'utils/queryParamsHelper'
import BaseTable from 'components/TableLibrary/BaseTable'
import Scrollbars from 'components/Scrollbars'
import handleBottomScroll from 'utils/handleBottomScroll'
import Timeline from './Timeline'
import {
  useLazyGetDeviceUpTimeQuery,
  useLazyPublicUptimeQuery
} from 'api/deviceApi'
import { featureNames } from 'constants/featureConstants'
import { uptimeSortTypes } from 'constants/libraryConstants/nocLibraryConstants'
import { getPlaceholderRowsCount } from 'utils/libraryUtils'
import DeviceUptimeLoader from 'components/Loaders/DeviceUptimeLoader'

const useStyles = makeStyles(({ typography, type }) => ({
  name: {
    ...typography.darkAccent[type]
  },
  label: {
    ...typography.subtitle[type]
  },
  aliasWrap: {
    display: 'flex'
  },
  aliasWrapContent: {
    minWidth: '190px'
  },
  dateTimeView: {
    cursor: 'pointer',
    position: 'relative'
  },
  deviceNameWrap: {
    display: 'flex',
    width: 'max-content'
  },
  ipText: {
    ...typography.darkAccent[type]
  },
  lanText: {
    ...typography.lightAccent[type]
  },
  tableRowRoot: {
    height: '4.4rem'
  },
  deviceIconWrapper: {
    width: '2.25rem',
    height: '2.25rem',

    '& img': {
      width: '2.25rem'
    }
  },
  scrollbarRoot: {
    '@supports ( -moz-appearance:none )': {
      '& > div': {
        marginBottom: '0px !important'
      }
    }
  },
  headerRoot: {
    display: 'none'
  }
}))

const defaultColumnWidth = {
  alias: 150,
  lastCheckInUTC: 200,
  status: 170,
  lanIP: 200,
  networkConnectivity: 200,
  synchronousConnection: 200
}

const LIBRARY_EXTRA_PAGE_HEIGHT = 350
const PUBLIC_EXTRA_PAGE_HEIGHT = 150
const ROW_HEIGHT = 72

const applySortParams = ({ sort, ...params }) => {
  let sortParams = {
    sort: 'alias',
    order: 'asc'
  }

  switch (sort) {
    case uptimeSortTypes.leastActive:
      sortParams = {
        sort: 'deviceUptime',
        order: 'asc'
      }
      break
    case uptimeSortTypes.mostActive:
      sortParams = {
        sort: 'deviceUptime',
        order: 'desc'
      }
      break
    default:
      break
  }

  return {
    ...params,
    ...sortParams
  }
}

const DeviceUptime = ({
  customColumnWidth = null,
  searchParams,
  filterParams,
  isPublic,
  parentClasses,
  autoScroll,
  isFetchAllowed,
  preferenceParams,
  preferenceActions
}) => {
  const classes = useStyles()

  const { token } = useParams()
  const [data, setData] = useState([])
  const [pageLimit, setPageLimit] = useState(0)

  const scrollInterval = useRef()
  const scrollRef = useRef()

  const role = useUserRole()
  const [
    getDeviceUpTime,
    { data: device, isFetching, isUninitialized, originalArgs }
  ] = useLazyGetDeviceUpTimeQuery()

  const [
    gePublicUpTime,
    {
      data: publicDevice,
      isFetching: isPublicUptimeFetching,
      isUninitialized: isPublicUptimeUninitialized,
      originalArgs: publicOriginalArgs
    }
  ] = useLazyPublicUptimeQuery()

  const isLoading = useMemo(
    () =>
      token
        ? isPublicUptimeFetching || isPublicUptimeUninitialized
        : isFetching || isUninitialized,
    [
      token,
      isFetching,
      isUninitialized,
      isPublicUptimeFetching,
      isPublicUptimeUninitialized
    ]
  )

  const getPageLimit = useCallback(() => {
    let height = 400
    if (scrollRef.current) {
      height =
        window.innerHeight -
        (isPublic ? PUBLIC_EXTRA_PAGE_HEIGHT : LIBRARY_EXTRA_PAGE_HEIGHT)
    }

    const rowsVisible = Math.ceil(height / ROW_HEIGHT)

    return rowsVisible * 2
  }, [isPublic])

  const fetcher = useCallback(
    (params = {}) => {
      const { filters, ...restPreferenceParams } = preferenceParams

      token
        ? gePublicUpTime(
            queryParamsHelper(
              applySortParams({
                ...restPreferenceParams,
                ...searchParams,
                ...filterParams,
                limit: pageLimit,
                ...params,
                token,
                feature_name: featureNames.DeviceNoc
              })
            )
          )
        : getDeviceUpTime(
            queryParamsHelper(
              applySortParams({
                ...restPreferenceParams,
                ...searchParams,
                ...filterParams,
                limit: pageLimit,
                ...params
              })
            )
          )
    },
    [
      preferenceParams,
      token,
      gePublicUpTime,
      searchParams,
      filterParams,
      pageLimit,
      getDeviceUpTime
    ]
  )

  const debounceFetcher = useDebouncedCallback(fetcher, 500)

  const handleReset = useCallback(() => {
    scrollRef.current && scrollRef.current.scrollToTop()
    debounceFetcher.callback()
  }, [debounceFetcher])

  const handleResizeWindow = useCallback(() => {
    const limit = getPageLimit()
    setPageLimit(limit)
  }, [getPageLimit])

  useEffect(() => {
    handleResizeWindow()
    window.addEventListener('resize', handleResizeWindow)
    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
    // eslint-disable-next-line
  }, [handleResizeWindow])

  useEffect(
    () => {
      if (isFetchAllowed && pageLimit) {
        handleReset()
      }
    },
    // eslint-disable-next-line
    [isFetchAllowed, filterParams, pageLimit]
  )

  useEffect(() => {
    const currentDevice = token ? publicDevice : device

    if (!isLoading && currentDevice && currentDevice.data.length) {
      if (currentDevice?.meta.currentPage > 1) {
        setData(_data => [..._data, ...currentDevice?.data])
      } else {
        setData(currentDevice?.data)
      }
    }
  }, [device, publicDevice, isFetching, isLoading, role.system, token])

  useEffect(() => {
    const currentDevice = token ? publicDevice : device

    if (
      currentDevice?.meta.lastPage > 0 &&
      currentDevice?.meta.currentPage === currentDevice?.meta.lastPage
    ) {
      setTimeout(() => {
        handleReset()
      }, 30000)
    }
    if (currentDevice?.meta.currentPage === 1 && autoScroll) {
      handleFetchMore()
    }
    // eslint-disable-next-line
  }, [device?.meta, publicDevice?.meta, token])

  const handleAutoScroll = useCallback(() => {
    if (scrollRef.current) {
      const { getClientHeight, getScrollTop, scrollToTop } = scrollRef.current
      const clientHeight = getClientHeight()
      const scrollTop = getScrollTop() || 0

      scrollToTop(scrollTop + clientHeight)
    }
  }, [])

  useEffect(() => {
    if (autoScroll && !scrollInterval.current) {
      handleFetchMore()
      scrollInterval.current = setInterval(handleAutoScroll, 10000)
    } else if (!autoScroll && scrollInterval.current) {
      clearInterval(scrollInterval.current)
      scrollInterval.current = null
    }
    // eslint-disable-next-line
  }, [autoScroll])

  const handleFetchMore = () => {
    const currentDevice = token ? publicDevice : device

    if (
      !isLoading &&
      currentDevice?.meta.currentPage + 1 <= currentDevice?.meta.lastPage
    ) {
      fetcher({
        page: currentDevice?.meta.currentPage + 1
      })
    }
  }

  const columnWidth = useMemo(
    () => (customColumnWidth ? customColumnWidth : defaultColumnWidth),
    [customColumnWidth]
  )

  return (
    <>
      <Scrollbars
        ref={scrollRef}
        onUpdate={handleBottomScroll(
          handleFetchMore,
          autoScroll ? scrollRef.current?.getClientHeight() || 50 : 50
        )}
        autoHeight
        autoHeightMax={`calc(100vh - ${
          isPublic ? PUBLIC_EXTRA_PAGE_HEIGHT : LIBRARY_EXTRA_PAGE_HEIGHT
        }px)`}
        renderHorizontalScroll
        className={classes.scrollbarRoot}
      >
        <BaseTable
          meta={token ? publicDevice?.meta : device?.meta}
          loading={
            isPublic
              ? isPublicUptimeUninitialized ||
                (isPublicUptimeFetching && !publicOriginalArgs?.page)
              : isUninitialized || (isFetching && !originalArgs?.page)
          }
          customLoader={
            <DeviceUptimeLoader
              rowCount={getPlaceholderRowsCount(
                token ? publicDevice?.meta?.perPage : device?.meta?.perPage,
                72
              )}
              width={isPublic ? 836 : 784}
              lineHeight={72}
              rowSpacing={82}
              offsetY={5}
              hideHeader
            />
          }
          isCustomLoading={true}
          fetcher={fetcher}
          noType={false}
          preferenceActions={preferenceActions}
          selectedListClearAllowed={false}
          placeholderMessage="No Results Found"
          columnWidth={columnWidth}
          hasDelete={false}
          tableRootClass={parentClasses.overviewTableRoot}
          tableFooterWrapClasName={parentClasses.overviewTableFooterWrap}
          paginationClasses={parentClasses.overviewPagination}
          tablePaperWrapperClass={parentClasses.overviewTablePaperWrapper}
          tableMaxWidth={isPublic ? 1600 : undefined}
          hideTableFooter
          isStickyHeader
          showEditRows={false}
          headerRootClassName={classes.headerRoot}
        >
          <Timeline data={data} />
        </BaseTable>
        {isPublic
          ? !isPublicUptimeUninitialized &&
            isPublicUptimeFetching &&
            !!publicOriginalArgs?.page
          : !isUninitialized &&
            isFetching &&
            !!originalArgs?.page && (
              <DeviceUptimeLoader
                rowCount={1}
                footerHeight={10}
                lineHeight={72}
                hideHeader
              />
            )}
      </Scrollbars>
      <DevicePreviewModal />
    </>
  )
}

DeviceUptime.propTypes = {
  classes: PropTypes.object.isRequired,
  getDeviceNocGeneralItems: PropTypes.func,
  library: PropTypes.object
}

const mapStateToProps = ({ deviceNoc }) => ({
  library: deviceNoc.general
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDeviceNocGeneralItems
    },
    dispatch
  )

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeviceUptime
)

import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'

import SignInWrapper from './SignInWrapper'
import useSnackbar from 'hooks/useSnackbar'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
import { useLazyGetMeQuery } from 'api/appApi'
import {
  useGoogleTfaLoginMutation,
  useLazyPasswordlessLoginQuery
} from 'api/authenticationApi'
import { userDetailsSuccess } from 'actions/userActions'
import { getUrlPrefix } from 'utils'
import transformPermissions from 'utils/transformPermissions'
import {
  getTokenNameByRole,
  storageRemoveItem,
  storageSetToken
} from 'utils/localStorage'
import { ORG_ROLE, ORIGINAL_USERS, tfaTypes } from 'constants/api'
import { routeByName } from 'constants/index'
import TwoFactorAuth from './SignIn/TwoFactorAuth'

const PasswordlessLogin = ({ tfaType }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { expires, token, signature, tfaAccessToken } = useUrlSearchParams()
  const history = useHistory()
  const { showSnackbar } = useSnackbar()

  const [getMe, meResult] = useLazyGetMeQuery()

  const passworldessLoginQuery = useLazyPasswordlessLoginQuery()
  const googleTfaLoginMutation = useGoogleTfaLoginMutation()

  const { action, result } = useMemo(() => {
    switch (tfaType) {
      case tfaTypes.googleTfa:
        return {
          action: googleTfaLoginMutation[0],
          result: googleTfaLoginMutation[1]
        }
      default:
        return {
          action: passworldessLoginQuery[0],
          result: passworldessLoginQuery[1]
        }
    }
  }, [passworldessLoginQuery, googleTfaLoginMutation, tfaType])

  const handleSubmit = useCallback(
    (code, role, isRecoveryCode) => {
      action({
        tfaAccessToken,
        code,
        isRecoveryCode
      })
    },
    [action, tfaAccessToken]
  )

  useEffect(() => {
    if (!tfaType) {
      return
    }
    if (expires && moment.unix(expires).isBefore(moment())) {
      history.push(`${routeByName.expiredPasswordlessLoginError}/?code=403`)
      return
    }

    if (token && signature) {
      action({
        token,
        signature,
        expires,
        withoutAuth: true
      })
    }
    // eslint-disable-next-line
  }, [expires, token, signature])

  useEffect(() => {
    if (result.isError) {
      if (result.error.code === 403) {
        history.push(`${routeByName.expiredPasswordlessLoginError}/?code=403`)
      } else {
        try {
          showSnackbar(
            result.error.errorFields?.length
              ? result.error.errorFields
                  .map(({ value }) => value.join(' '))
                  .join('')
              : result.error.message,
            'error'
          )
        } catch (e) {
          showSnackbar(result.error.message, 'error')
        }
      }
    } else if (result.isSuccess) {
      const { tokenType, accessToken, expiresIn } = result.data || {}

      storageRemoveItem(ORIGINAL_USERS)
      storageSetToken(
        getTokenNameByRole(ORG_ROLE),
        tokenType,
        accessToken,
        expiresIn
      )

      getMe()
    }
    // eslint-disable-next-line
  }, [result])

  useEffect(() => {
    if (meResult.isSuccess) {
      const { tokenType, accessToken, expiresIn } = result.data || {}
      const user = meResult.data

      if (ORG_ROLE !== user.role.level) {
        storageSetToken(
          getTokenNameByRole(user.role.level),
          tokenType,
          accessToken,
          expiresIn
        )
      }

      dispatch(
        userDetailsSuccess({
          ...user,
          permissions: transformPermissions(user.role.permission)
        })
      )
      history.push(getUrlPrefix('dashboard'))
    } else if (meResult.isError) {
      showSnackbar(t('Oops.. Something went wrong'), 'error')
    }
    // eslint-disable-next-line
  }, [meResult])

  const isLoading =
    !tfaType &&
    (result.isFetching ||
      result.isUninitialized ||
      meResult.isFetching ||
      meResult.isUninitialized)

  return (
    <SignInWrapper title={t('Login')} isManually={!!tfaType}>
      {isLoading && <CircularProgress size={30} thickness={5} />}
      {!!tfaType && (
        <TwoFactorAuth
          tfaType={tfaType}
          isTwoFactorLoading={result.isLoading || meResult.isFetching}
          onValidateCode={handleSubmit}
          onCancel={() => history.push('/sign-in')}
          role={ORG_ROLE}
        />
      )}
    </SignInWrapper>
  )
}

export default PasswordlessLogin

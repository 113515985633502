import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'

export const contentAppHelpPagesApi = createAppApi('contentAppHelpPages', {
  tagTypes: [apiTags.contentAppHelpPages],
  endpoints: builder => ({
    getContentAppHelpPages: builder.query({
      providesTags: [apiTags.contentAppHelpPages],
      query: (params = {}) => ({
        url: '/contentAppHelp',
        params
      })
    }),
    putContentAppHelpPages: builder.mutation({
      invalidatesTags: [apiTags.contentAppHelpPages],
      query: ({ id, data = {} }) => ({
        url: `/contentAppHelp/${id}`,
        method: 'PUT',
        data
      })
    })
  })
})

export const {
  useGetContentAppHelpPagesQuery,
  usePutContentAppHelpPagesMutation
} = contentAppHelpPagesApi

import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { withTheme } from '@material-ui/core'

const PlaceholderRow = memo(({ rowY, lineHeight, width }) => (
  <>
    <rect x="10" y={rowY} rx="5" ry="5" width={width} height={lineHeight} />
    <rect
      x={width + 20}
      y={rowY}
      rx="5"
      ry="5"
      width={width}
      height={lineHeight}
    />
  </>
))

const PlaceholderHeader = memo(({ dividerY, lineHeight }) => (
  <PlaceholderRow
    leftColY={20}
    rowY={20}
    dividerY={dividerY}
    lineHeight={lineHeight}
  />
))

const DeviceUptimeLoader = ({
  theme,
  rowCount = 10,
  rowSpacing = 70,
  lineHeight = 24,
  headerHeight = 46,
  headerLineHeight = 12,
  footerHeight = 50,
  offsetY = 0,
  hideHeader = false,
  width = 784
}) => {
  const _headerHeight = hideHeader ? 0 : headerHeight
  return (
    <ContentLoader
      style={{
        width: '100%',
        height: rowCount * rowSpacing + _headerHeight + footerHeight
      }}
      backgroundColor={theme.palette[theme.type].loader.background}
      foregroundColor={theme.palette[theme.type].loader.foreground}
    >
      {!hideHeader && (
        <PlaceholderHeader
          dividerY={headerHeight}
          lineHeight={headerLineHeight}
        />
      )}

      {new Array(rowCount).fill(0).map((a, i) => (
        <PlaceholderRow
          key={i}
          leftColY={
            i * rowSpacing + _headerHeight + (rowSpacing - lineHeight) / 2
          }
          rowY={
            i * rowSpacing +
            _headerHeight +
            (rowSpacing - lineHeight) +
            offsetY / 2
          }
          dividerY={(i + 1) * rowSpacing + _headerHeight}
          lineHeight={lineHeight}
          width={width}
        />
      ))}
    </ContentLoader>
  )
}

DeviceUptimeLoader.propTypes = {
  rowCount: PropTypes.number,
  rowSpacing: PropTypes.number,
  lineHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  footerHeight: PropTypes.number,
  headerLineHeight: PropTypes.number,
  hideHeader: PropTypes.bool
}

export default withTheme(DeviceUptimeLoader)

import axios from 'axios'
import * as config from '../config'
import { getToken, getAccessUrlWithoutEnterprise, errorHandler } from 'utils'
import getUserRoleLevel from 'utils/getUserRoleLevel'
import store from 'store/configureStore'
import { setPendingStatus } from 'actions/appActions'
import { asyncRefreshToken } from 'utils'
import { createApi } from '@reduxjs/toolkit/query/react'

const urlsWithoutPrefix = [
  'system/login',
  'org/login',
  'system/twoFactorLogin',
  'org/twoFactorLogin',
  'system/recovery',
  'org/recovery',
  'system/reset',
  'org/info',
  'org/socialLogin',
  'system/socialLogin',
  'enterprise/feedback',
  'org/feedback',
  '/content/approve',
  '/org/public/noc/general',
  '/org/public/noc/health',
  '/org/public/noc/network',
  '/org/login/noc'
]

const urlsWithoutEnterprisePrefix = [
  '/login',
  '/feedback',
  '/logout',
  '/sociallogin',
  '/recovery',
  '/reset',
  '/refresh',
  '/me',
  '/content/approve'
]

const urlsWithoutPending = ['/font', '/quote']

let cancelTokenSource
const BaseAxiosInstance = axios.create({
  baseURL: config.API_URL,
  responseType: 'json'
})

let requestPending = 0

BaseAxiosInstance.interceptors.request.use(req => {
  if (!urlsWithoutPending.includes(req.url) && !req.silent) {
    requestPending++
  }
  if (requestPending === 1) {
    store.dispatch(setPendingStatus(true))
  }
  const headers = { ...req.headers }

  if (!req.withoutAuth && !headers.Authorization && getToken()) {
    headers.Authorization = getToken()
  }

  let baseURL = req.baseURL

  if (!urlsWithoutPrefix.includes(req.url) && !req.impersonated) {
    const prefix = urlsWithoutEnterprisePrefix.includes(req.url)
      ? getAccessUrlWithoutEnterprise()
      : getUserRoleLevel()

    baseURL = `${baseURL}/${prefix}`
  }

  return {
    ...req,
    headers,
    baseURL
  }
})

BaseAxiosInstance.interceptors.response.use(
  config => {
    requestPending = Math.max(0, --requestPending)
    if (requestPending === 0) store.dispatch(setPendingStatus(false))

    return config
  },
  err => {
    const { config, response } = err
    requestPending = Math.max(0, --requestPending)
    if (requestPending === 0) store.dispatch(setPendingStatus(false))
    if (
      response?.status === 401 &&
      !config.url.includes('/login') &&
      !config.url.includes('/socialLogin') &&
      !config.url.includes('/twoFactorLogin') &&
      !config.url.includes('/powerBI/dashboards') &&
      !config.url.includes('/klipfolio/dashboards') &&
      !config.url.includes('/oauth2/klipfolio') &&
      !config.url.includes('/oauth2/tableau') &&
      !config.url.includes('/tableau/views') &&
      !config.url.includes('/tableau/workbooks') &&
      !config.url.includes('/org/login/noc') &&
      !config.url.includes('/postHog') &&
      !config.url.includes('googleTwoFactorLogin') &&
      response?.data?.message !==
        'Access restricted. Please contact your account administrator to review your permissions.'
    ) {
      return asyncRefreshToken(config, err)
    }
    return Promise.reject(err)
  }
)

const Cache = {}
const GroupRequests = {}

export default function api({ useCache, useGroup, ...params }) {
  const cancelable = params?.params?.cancelable || false

  if (cancelable && cancelTokenSource) {
    cancelTokenSource.cancel('Operation canceled due to new request.')
  }

  if (cancelable) {
    delete params.params.cancelable
    //TODO rewrite with AbortController https://www.npmjs.com/package/axios#abortcontroller
    const source = axios.CancelToken.source()
    cancelTokenSource = source
    params.cancelToken = source.token
  }

  return new Promise((resolve, reject) => {
    const cacheKey = JSON.stringify(params)

    const handleSuccess = res => {
      if (useCache) {
        Cache[cacheKey] = res
      }
      resolve(res)
    }

    const handleError = error => {
      if (axios.isCancel(error)) {
        return
      }
      reject(error)
    }

    if (useCache && cacheKey in Cache) {
      return resolve(Cache[cacheKey])
    }

    if (useGroup && cacheKey in GroupRequests) {
      GroupRequests[cacheKey].then(handleSuccess).catch(handleError)
      return
    }

    if (useGroup) {
      GroupRequests[cacheKey] = BaseAxiosInstance(params)
      GroupRequests[cacheKey]
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          delete GroupRequests[cacheKey]
        })
      return
    }

    BaseAxiosInstance(params).then(handleSuccess).catch(handleError)
  })
}

const parseBaseQueryResult = (data, error, request, response) => ({
  data,
  error,
  meta: {
    request,
    response
  }
})

const appBaseQuery = async args => {
  try {
    const response = await api(args)
    return parseBaseQueryResult(
      response.data,
      undefined,
      response.request,
      response
    )
  } catch (error) {
    const { response, request } = error
    return parseBaseQueryResult(
      undefined,
      errorHandler(error),
      request,
      response
    )
  }
}

const appFetchBaseQuery = async args => {
  if (typeof args === 'string') {
    args = {
      url: args
    }
  }
  const result = await appBaseQuery({
    ...args,
    ...(args.body ? { data: args.body } : {})
  })
  return result
}

export const createAppApi = (reducerPath, config = {}) =>
  createApi({
    reducerPath,
    baseQuery: appFetchBaseQuery,
    ...config
  })

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'

import ActivationContent from './ActivationContent'
import DefaultModal from 'components/Modal/DefaultModal'
import useUserDetails from 'hooks/useUserDetails'
import useMutationResultHandler from 'hooks/useMutationResultHandler'
import {
  useActivateGoogle2faMutation,
  useRegisterGoogle2faMutation
} from 'api/authenticationApi'
import { _get } from 'utils/lodash'
import { getUserDetailsAction } from 'actions/userActions'
import { setRecoveryCodes } from 'slices/appSlice'
import { requiredField } from 'constants/validationMessages'
import { CLIENT_ADMIN } from 'constants/roles'

const GoogleAuthenticatorSetupModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isNeedToSetupGoogle2FA, role } = useUserDetails()

  const [register, registerResult] = useRegisterGoogle2faMutation()
  const [activate, activateResult] = useActivateGoogle2faMutation()

  const [open, setOpen] = useState(false)

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleBlur,
    isValid,
    handleSubmit
  } = useFormik({
    initialValues: {
      code: '',
      secret: ''
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required(requiredField)
    }),
    onSubmit: values => {
      activate(values)
    }
  })

  useEffect(() => {
    setOpen(!!isNeedToSetupGoogle2FA)
    if (isNeedToSetupGoogle2FA) {
      register()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (registerResult.data) {
      setFieldValue('secret', registerResult.data.secret)
    }
    // eslint-disable-next-line
  }, [registerResult])

  useMutationResultHandler({
    result: activateResult,
    keyWord: 'Google Authenticator',
    actionText: 'activated',
    onError: result => {
      const errors = _get(result, 'error.errorFields', [])
      if (errors.length) {
        errors.forEach(field => {
          setFieldError(field.name, (field.value || '').join(' '))
        })
      }
    },
    onSuccess: ({ data }) => {
      if (data?.recoveryCodes?.length) {
        dispatch(setRecoveryCodes(data?.recoveryCodes))
      }
      dispatch(getUserDetailsAction())
    }
  })

  return (
    <DefaultModal
      modalTitle={t('Google Authenticator Setup')}
      open={open}
      hasCloseIcon={false}
      buttonSecondaryText={t('Later')}
      contentPaddingBottom
      hasCancelBtn={role?.name === CLIENT_ADMIN}
      onClickSave={handleSubmit}
      buttonPrimaryOpaque={!isValid}
      onClickCancel={() => setOpen(false)}
      isSaveLoad={activateResult.isLoading}
    >
      <ActivationContent
        isLoading={registerResult.isLoading}
        qrCodeUrl={registerResult.data?.qrCodeUrl}
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </DefaultModal>
  )
}

export default GoogleAuthenticatorSetupModal

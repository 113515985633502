import api from './api'
import { errorHandler } from '../utils'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import { API_URL } from '../config'

export async function loginUserService({
  username,
  password,
  rememberMe,
  sendCodeViaEmail,
  role
}) {
  try {
    const { data } = await api({
      method: 'POST',
      url: `${role}/login`,
      data: { username, password, rememberMe, sendCodeViaEmail }
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const register = async data => {
  try {
    return await api({
      method: 'POST',
      url: '/register',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function twoFactorService({ code, role, tfaAccessToken }) {
  try {
    const { data } = await api({
      method: 'POST',
      url: `${role}/twoFactorLogin`,
      data: { code, tfaAccessToken }
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function googleTwoFactorLogin({
  code,
  tfaAccessToken,
  isRecoveryCode
}) {
  try {
    const { data } = await api({
      method: 'POST',
      url: 'googleTwoFactorLogin',
      data: { code, tfaAccessToken, isRecoveryCode }
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const loginSocialService = async request => {
  try {
    const {
      data: { provider, token, code, tokenId },
      role
    } = request

    const { data } = await api({
      method: 'POST',
      url: `${role}/socialLogin`,
      data: { provider, token, code, tokenId }
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getSSODataService = async request => {
  try {
    const {
      data: { provider, token }
    } = request

    const { data } = await api({
      method: 'POST',
      url: `socialUserData`,
      data: { provider, token }
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const logoutUserService = async () => {
  try {
    const response = await api({
      method: 'POST',
      url: '/logout'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const updateTokenService = async impersonated => {
  try {
    const response = await api({
      method: 'POST',
      url: '/refresh',
      ...(impersonated && getApiParamsByOriginalUser('/refresh'))
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const reset = async ({ system, ...data }) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${system ? 'system' : ''}/reset`,
      data: data
    })

    return response
  } catch (error) {
    throw errorHandler(error)
  }
}

export const impersonateUserService = async userId => {
  try {
    const response = await api({
      method: 'POST',
      url: `/user/${userId}/impersonate`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const impersonateSystemUserService = async userId => {
  try {
    const response = await api({
      method: 'POST',
      url: `/client/user/${userId}/impersonate`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getImpersonateToken = async id => {
  try {
    const response = await api({
      method: 'POST',
      url: `/client/user/${id}/impersonate`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const emailVerify = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `${API_URL.replace('/api/v1', '')}/user/email-verify`,
      params
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

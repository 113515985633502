import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Spacing from 'components/Containers/Spacing'
import { Text } from 'components/Typography'
import Tooltip from 'components/Tooltip'

const useStyles = makeStyles(({ palette, type, spacing }) => ({
  copyWrapper: {
    position: 'relative',
    backgroundColor: palette[type].secondary,
    padding: spacing(1),
    borderRadius: 4
  },
  copyIcon: {
    position: 'absolute',
    right: spacing(1),
    top: spacing(1),
    color: palette[type].formControls.input.color,
    fontSize: 18,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in',
    '&:hover': {
      transform: 'scale(1.2)'
    }
  }
}))

const RecoveryCodesContent = ({ recoveryCodes = [] }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [tooltip, setTooltip] = useState(t('Click to copy'))

  return (
    <>
      <Spacing>
        <Text color="title.primary" weight="bold">{`${t(
          'Please copy and save the recovery codes before closing the modal'
        )}:`}</Text>
      </Spacing>
      <CopyToClipboard
        onCopy={() => {
          setTooltip(t('Copied to clipboard'))
          setTimeout(() => setTooltip(t('Click to copy')), 2000)
        }}
        text={recoveryCodes.join('\n')}
      >
        <div className={classes.copyWrapper}>
          <Tooltip title={tooltip} arrow position="top">
            <i
              className={classNames('fa-regular fa-paste', classes.copyIcon)}
            />
          </Tooltip>
          <Text whiteSpace="pre">{recoveryCodes.join('\n')}</Text>
        </div>
      </CopyToClipboard>
    </>
  )
}

export default RecoveryCodesContent

import apiTags from 'constants/apiTags'
import { createAppApi } from 'services/api'
import { createSuccessInvalidator, getCRUDEndpoints } from 'utils/apiUtils'

export const contentsApi = createAppApi('contents', {
  tagTypes: [apiTags.contents],
  endpoints: builder => ({
    getContent: builder.query({
      query: id => ({ url: `/feature/content/${id}` })
    }),
    getContents: builder.query({
      query: ({ feature, ...params }) => ({
        url: `/feature/${feature}/content`,
        params: {
          ...params
        }
      })
    }),
    postContent: builder.mutation({
      query: ({ feature, data }) => ({
        url: `/feature/${feature}/content`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: createSuccessInvalidator([apiTags.contents])
    }),
    putContent: builder.mutation({
      query: ({ id, data }) => ({
        url: `/feature/content/${id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: createSuccessInvalidator([apiTags.contents])
    }),
    deleteContent: builder.mutation({
      query: id => ({
        url: `/feature/content/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: createSuccessInvalidator([apiTags.contents])
    }),
    ...getCRUDEndpoints({
      name: 'HTMLContent',
      url: '/HTMLContent',
      builder,
      tags: [apiTags.contents]
    })
  })
})

export const {
  useLazyGetContentQuery,
  useLazyGetContentsQuery,
  usePostContentMutation,
  usePutContentMutation,
  useDeleteContentMutation,
  useGetHTMLContentsQuery
} = contentsApi

import React from 'react'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import SignInContent from 'components/Account/SignIn/SignInContent'
import TwoFactorAuth from 'components/Account/SignIn/TwoFactorAuth'
import useAuth from 'hooks/api/useAuth'
import useWhiteLabel from 'hooks/useWhiteLabel'
import { ORG_ROLE, SYSTEM_ROLE } from 'constants/api'
import SignInWrapper from '../SignInWrapper'

function SignIn({
  t,
  role = ORG_ROLE,
  title = '',
  displayIp = false,
  isManually = false
}) {
  const { sso, isAccessDenied } = useWhiteLabel()
  const {
    isPending,
    isTwoFactor,
    isTwoFactorPending,
    onCodeInputRef,
    onCancel,
    loginUser,
    validateCode,
    error,
    tfaType
  } = useAuth()
  const isSystemUser = role === SYSTEM_ROLE

  if (isAccessDenied) {
    return <Redirect to="/access-denied" />
  }

  return (
    <SignInWrapper
      title={t(title)}
      isSystemUser={isSystemUser}
      isManually={isManually}
      isSigninPage={true}
    >
      {isTwoFactor ? (
        <TwoFactorAuth
          role={role}
          isTwoFactorLoading={isTwoFactorPending}
          onCodeInputRef={onCodeInputRef}
          onCancel={onCancel}
          onValidateCode={validateCode}
          tfaType={tfaType}
        />
      ) : (
        <SignInContent
          displayIp={displayIp}
          sso={sso}
          role={role}
          onSubmit={loginUser}
          isLoading={isPending}
          error={error}
          isManually={isManually}
        />
      )}
    </SignInWrapper>
  )
}

SignIn.propTypes = {
  role: PropTypes.oneOf([ORG_ROLE, SYSTEM_ROLE]),
  enqueueSnackbar: PropTypes.func,
  title: PropTypes.string,
  displayIp: PropTypes.bool,
  system: PropTypes.bool
}

export default withTranslation('translations')(SignIn)
